import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../containers/layout';
import PageTransition from '../components/common/page-transition';
import PaddedPage from '../components/common/padded-page';

const Contact = () => (
  <Layout>
    <PageTransition>
      <PaddedPage>
        <div>contact</div>
      </PaddedPage>
    </PageTransition>
  </Layout>
);

export default Contact;
